import React from "react";
import {ReactComponent as AppLoaderSvg} from "../../assets/appLoader.svg";

const AppLoader = () => {
    return (
        <div style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
        }}>
            <div style={{
                background: 'red',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
            }}>
                <AppLoaderSvg/>
            </div>
        </div>
    )
}

export default AppLoader;
