import React from "react";
import AppLoader from "./components/loaders/AppLoader";


function LazzyApp() {

    const App = React.lazy(() => import("./App"));

    return (
        <React.Suspense fallback={<AppLoader/>}>
            <App/>
        </React.Suspense>
    )
}

export default LazzyApp;
